import React, { ChangeEvent, useContext, useEffect, useState, useMemo } from 'react';
import {
  PlusIcon,
  TrashIcon,
  EyeIcon,
  ChatIcon,
  MailIcon,
  ChevronDownIcon,
  CalendarIcon,
  LockClosedIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/react/solid';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment';
import CustomSelect from 'components/CustomSelect';
import axios from 'axios';
import classNames from 'classnames';
import { toast } from 'react-toast';
import { Disclosure } from '@headlessui/react';
import firebase from 'services/firebase';
import Table from 'components/Table';

import { AuthContext } from 'providers/AuthProvider';
import _debounce from 'lodash/debounce';
import { getRole, getRoleByLabel } from 'shared/functions/global';
import DatePicker, { registerLocale } from 'react-datepicker';
import Headline from 'components/Headline';
import { ROLE_COMPANY_ADMIN, ROLE_LOCATION_ADMIN, ROLE_MEMBER, ROLE_TRAINER } from 'shared/constants/global';
import Overlay from 'components/Overlay';
import Button from 'components/Button';
import CustomInput from 'components/Input/custom-input';

import styles from './styles.module.scss';

const skeletonTable: TableDataType[][] = new Array(3).fill([
  { type: 'text', value: '' },
  { type: 'text', value: '' },
  { type: 'text', value: '' },
  { type: 'text', value: '' },
]);

export default function CompanyMember() {
  const { t } = useTranslation();
  const { tenant, tenantData, userData } = useContext(AuthContext);
  const [memberData, setMemberData] = useState<TableDataType[][]>([]);
  const [readMore, setReadMore] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [isPendingDeleteByDate, setIsPendingDeleteByDate] = useState(false);
  const [isDisablePending, setIsDisablePending] = useState(false);
  const [initialTrainerLoad, setInitialTrainerLoad] = useState(false);
  const [deleteProfilePopupClass, setDeleteProfilePopupClass] = useState('hidden');
  const [notePopupClass, setNotePopupClass] = useState('hidden');
  const [refreshFilter, setRefreshFilter] = useState(0);
  const [disableAccountPopupClass, setDisableAccountPopupClass] = useState('hidden');
  const [reactivateAccountPopupClass, setReactivateAccountPopupClass] = useState('hidden');
  const [sendShareMailClass, setSendShareMailClass] = useState('hidden');
  const [deleteByDateEditClass, setDeleteByDateEditClass] = useState('hidden');
  const [selectedUser, setSelectedUser] = useState<UserInfo>({} as UserInfo);
  const [selectedUserCommentList, setSelectedUserCommentList] = useState<any>([]);
  const [selectedUserInitialCommentList, setSelectedUserInitialCommentList] = useState<any>([]);
  const [search, setSearch] = useState<string>('');
  const [noteSearch, setNoteSearch] = useState<string>('');
  const [filteredMemberData, setFilteredMemberData] = useState<TableDataType[][]>([]);
  const [filteredMemberDataForLength, setFilteredMemberDataForLength] = useState<TableDataType[][]>([]);
  const [comment, setComment] = useState<string>('');
  const [currentFilter, setCurrentFilter] = useState<number | undefined>(undefined);
  const [filterInvitedMembers, setFilterInvitedMembers] = useState<boolean | undefined>(undefined);
  const [filterActiveMembers, setFilterActiveMembers] = useState<boolean | undefined>(undefined);
  const [disabledInvitedMembers, setDisabledInvitedMembers] = useState<boolean | undefined>(undefined);
  const [currentLocation, setCurrentLocation] = useState<number | undefined>(undefined);
  const [currentTrainer, setCurrentTrainer] = useState<string | undefined>(undefined);
  const [member, setMember] = useState<UserInfo[] | undefined>(undefined);
  const [invitedMembers, setInvitedMembers] = useState<UserInfo[] | undefined>(undefined);
  const [currentUserObject, setCurrentUserObject] = useState<UserInfo>({} as UserInfo);
  const [trainerOptions, setTrainerOptions] = useState<any>([]);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [currentResultPerPage, setCurrentResultPerPage] = useState(10);
  const [pagesOptions, setPagesOptions] = useState<any>([]);
  const [currentPageForDropDown, setCurrentPageForDropDown] = useState(0);
  const [currentDisplayedMemberCount, setCurrentDisplayedMemberCount] = useState(0);

  const [startDate, setStartDate] = useState(null);

  const authContext = useContext(AuthContext);

  const trainerSelectRef = React.useRef() as any;

  const handleSearch = (value: string) => {
    setCurrentPageIndex(0);
    setCurrentPageForDropDown(0);
    setSearch(value);
  };

  const onChange = (dates: any) => {
    setStartDate(dates);
  };

  // onChange-Handler mit Debounce
  const handleSearchDebounced = useMemo(() => _debounce(handleSearch, 300), []);

  const locationOptions: any =
    [...(tenantData?.settings?.locations ?? [])]?.map(item => {
      return { value: item.id, label: item.name };
    }) ?? [];

  const pagePerSiteOptions = [
    {
      value: 10,
      label: '10',
    },
    {
      value: 25,
      label: '25',
    },
    {
      value: 50,
      label: '50',
    },
  ];

  locationOptions.unshift({ value: 0, label: 'Alle' });

  const header = [
    t('Name'),
    t('Email'),
    t('Rolle'),
    t('Trainer'),
    t('Status'),
    t('Nutzer seit'),
    t('Letzter Login'),
    '',
    '',
    '',
  ];

  const customHeader = [
    {
      label: t('Name'),
      sortId: 'fullName',
      isDate: false,
      isSortable: true,
    },
    {
      label: t('Email'),
      sortId: 'email',
      isDate: false,
      isSortable: true,
    },
    {
      label: t('Rolle'),
      sortId: 'role',
      isDate: false,
      isSortable: true,
    },
    {
      label: t('Trainer'),
      sortId: 'trainer',
      isDate: false,
      isSortable: false,
    },
    {
      label: t('Status'),
      sortId: 'status',
      isDate: false,
      isSortable: true,
    },
    {
      label: t('Nutzer seit'),
      sortId: 'createdDate',
      isDate: true,
      isSortable: true,
    },
    {
      label: t('Letzter Login'),
      sortId: 'lastLogin',
      isDate: true,
      isSortable: true,
    },
    {
      label: '',
    },
    {
      label: '',
    },
    {
      label: '',
    },
  ];

  const customHeaderWithLocations = [
    {
      label: t('Name'),
      sortId: 'fullName',
      isDate: false,
      isSortable: true,
    },
    {
      label: t('Email'),
      sortId: 'email',
      isDate: false,
      isSortable: true,
    },
    {
      label: t('Rolle'),
      sortId: 'role',
      isDate: false,
      isSortable: true,
    },
    {
      label: t('Trainer'),
      sortId: 'trainer',
      isDate: false,
      isSortable: false,
    },
    {
      label: 'Standort',
      sortId: 'location',
      isDate: false,
      isSortable: false,
    },
    {
      label: t('Status'),
      sortId: 'status',
      isDate: false,
      isSortable: true,
    },
    {
      label: t('Nutzer seit'),
      sortId: 'createdDate',
      isDate: true,
      isSortable: true,
    },
    {
      label: t('Letzter Login'),
      sortId: 'lastLogin',
      isDate: true,
      isSortable: true,
    },
    {
      label: '',
    },
    {
      label: '',
    },
    {
      label: '',
    },
  ];

  const getLocationValue = (userItem: any) => {
    if (userItem.location === undefined || tenantData?.settings?.locations === undefined) {
      return 'hide';
    }

    const getValue = tenantData?.settings?.locations?.filter(item => item.id === userItem.location)[0];

    if (getValue !== undefined) {
      return getValue.id;
    }
    return '';
  };

  const getLocationValueName = (userItem: any) => {
    if (userItem.location === undefined || tenantData?.settings?.locations === undefined) {
      return 'hide';
    }

    const getValue = tenantData?.settings?.locations?.filter(item => item.id === userItem.location)[0];

    if (getValue !== undefined) {
      return getValue.name;
    }
    return '';
  };

  const getTenantLocationValueName = (tenantLocation: any) => {
    if (tenantLocation === undefined || tenantData?.settings?.locations === undefined) {
      return 'hide';
    }

    const getValue = tenantData?.settings?.locations?.filter(item => item.id === tenantLocation)[0];

    if (getValue !== undefined) {
      return getValue.name;
    }
    return '';
  };

  const handleDelete = ({ user }: { user: UserInfo }) => {
    setSelectedUser(user);
    setSelectedOption('direct');
    setStartDate(null);
    setDeleteProfilePopupClass('block');
  };

  const sortDataArray = [
    { name: 'fullName', index: 0 },
    { name: 'email', index: 1 },
    { name: 'role', index: 2 },
    { name: 'trainer', index: 3 },
    { name: 'status', index: 4 },
    { name: 'createdDate', index: 5 },
    { name: 'lastLogin', index: 6 },
  ];

  const openDisablePopup = (userObject: any) => {
    setCurrentUserObject(userObject);
    setDisableAccountPopupClass('block');
  };

  const openReactivatePopup = (userObject: any) => {
    setCurrentUserObject(userObject);
    setReactivateAccountPopupClass('block');
  };

  const sortTableData = (sortType: string, sortDirection: string, isDate: boolean) => {
    const test = filteredMemberData;
    const getIndex = sortDataArray.filter(item => item.name === sortType)[0];

    if (getIndex !== undefined) {
      if (sortDirection === 'asc') {
        if (isDate) {
          const sortData = test
            .slice()
            .sort((a, b) =>
              moment(b[getIndex.index].value, 'DD.MM.YY').format('YYYYMMDD') >
              moment(a[getIndex.index].value, 'DD.MM.YY').format('YYYYMMDD')
                ? 1
                : -1
            );
          setFilteredMemberData(sortData);
        } else {
          const sortData = test.slice().sort((a, b) => (b[getIndex.index].value > a[getIndex.index].value ? 1 : -1));
          setFilteredMemberData(sortData);
        }
      } else if (isDate) {
        const sortData = test
          .slice()
          .sort((a, b) =>
            moment(a[getIndex.index].value, 'DD.MM.YY').format('YYYYMMDD') >
            moment(b[getIndex.index].value, 'DD.MM.YY').format('YYYYMMDD')
              ? 1
              : -1
          );
        setFilteredMemberData(sortData);
      } else {
        const sortData = test.slice().sort((a, b) => (a[getIndex.index].value > b[getIndex.index].value ? 1 : -1));
        setFilteredMemberData(sortData);
      }
    } else {
      toast.error('Bei der Sortierung ist leider ein Fehler aufgetreten!');
    }
  };

  const deleteUserAccount = async () => {
    if (selectedUser.type === 'invite') {
      await firebase.firestore().collection('user-invitations').doc(selectedUser.uid).delete();
    } else {
      await firebase.firestore().collection(`tenants/${tenant}/users`).doc(selectedUser.uid).update({ toDelete: true });
    }

    setSelectedUser({} as UserInfo);
  };

  const deleteUserByDate = async () => {
    setIsPendingDeleteByDate(true);

    if (startDate === null) {
      toast.error('Bitte wählen Sie ein Datum aus!');
      setIsPendingDeleteByDate(false);
    } else {
      const thisSelectedUser = selectedUser as any;
      const date = moment(startDate, 'DD.MM.YYYY');
      const unixTimestamp = date.startOf('day').unix();

      try {
        if (selectedUser.type === 'invite') {
          await firebase.firestore().collection('tenantUserByDateDelete').doc(thisSelectedUser.uid).set({
            uid: thisSelectedUser.uid,
            type: 'invite',
            tenant: thisSelectedUser.tenant,
            nextDate: unixTimestamp,
          });

          await firebase
            .firestore()
            .collection('user-invitations')
            .doc(selectedUser.uid)
            .update({ deleteDate: unixTimestamp });
        } else {
          await firebase.firestore().collection('tenantUserByDateDelete').doc(thisSelectedUser.uid).set({
            uid: thisSelectedUser.uid,
            type: 'active',
            tenant,
            nextDate: unixTimestamp,
          });

          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(selectedUser.uid)
            .update({ deleteDate: unixTimestamp });

          toast.success('Der Löschung des Mitglieds wurde vorgemerkt!');
        }
      } catch (error) {
        console.log(error);
        setIsPendingDeleteByDate(false);
        toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
      }

      setIsPendingDeleteByDate(false);
      setDeleteProfilePopupClass('hidden');
      setSelectedUser({} as UserInfo);
    }
  };

  const handleNote = ({ user }: { user: UserInfo }) => {
    setSelectedUser(user);

    if (user.commentList !== undefined) {
      setSelectedUserCommentList([...user.commentList]);
      setSelectedUserInitialCommentList([...user.commentList]);
    } else {
      setSelectedUserCommentList([]);
      setSelectedUserInitialCommentList([]);
    }

    setNotePopupClass('block');
  };

  const deleteNote = async (dateId: number) => {
    if (selectedUser.commentList !== undefined) {
      const newColumns = {
        ...selectedUser,
        commentList: [...selectedUser.commentList.filter((c: any) => c.dateSeconds !== dateId)],
      };

      if (selectedUser.type === 'invite') {
        await firebase.firestore().collection('user-invitations').doc(selectedUser.uid).update(newColumns);
      } else {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(selectedUser.uid).update(newColumns);
      }
      setSelectedUser({} as UserInfo);
      setComment('');
      setNotePopupClass('hidden');
      toast.success('Deine Notiz wurde erfolgreich gelöscht!');
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut!');
    }
  };

  const updateNote = async (dateId: number) => {
    if (selectedUser.commentList !== undefined) {
      const newColumns = {
        ...selectedUser,
        commentList: selectedUser.commentList.map((entries: any) => {
          if (dateId !== entries.dateSeconds) return entries;
          return {
            ...entries,
            comment,
          };
        }),
      };

      if (selectedUser.type === 'invite') {
        await firebase.firestore().collection('user-invitations').doc(selectedUser.uid).update(newColumns);
      } else {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(selectedUser.uid).update(newColumns);
      }
      setSelectedUser({} as UserInfo);
      setComment('');
      setNotePopupClass('hidden');
      toast.success('Deine Notiz wurde erfolgreich gespeichert!');
    } else {
      toast.error('Es ist leider etwas schief gelaufen. Bitte probiere es erneut!');
    }
  };

  const saveNote = async () => {
    let noteForCurrentDayFound = false;

    if (selectedUser.commentList !== undefined) {
      for (let i = 0; i <= selectedUser?.commentList?.length - 1; i += 1) {
        if (selectedUser.commentList[i].date === moment().utc().format('DD.MM.YYYY')) {
          noteForCurrentDayFound = true;
        }
      }
    }

    if (noteForCurrentDayFound) {
      toast.error(
        'Du hast an diesem Tag schon einen Kommentar eingetragen. Bitte nutze das Feld im oberen Bereich bei dem Datum!'
      );
    } else {
      const thisNewObject = {
        comment,
        date: moment().format('DD.MM.YYYY'),
        dateSeconds: moment().unix(),
      };

      let newColumns = {};

      if (selectedUser.commentList !== undefined) {
        newColumns = {
          ...selectedUser,
          commentList: [thisNewObject, ...(selectedUser.commentList as any[])],
        };
      } else {
        newColumns = {
          ...selectedUser,
          commentList: [thisNewObject],
        };
      }

      if (selectedUser.type === 'invite') {
        await firebase.firestore().collection('user-invitations').doc(selectedUser.uid).update(newColumns);
      } else {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(selectedUser.uid).update(newColumns);
      }
      setSelectedUser({} as UserInfo);
      setComment('');
      setNotePopupClass('hidden');
      toast.success('Deine Notiz wurde erfolgreich gespeichert!');
    }
  };

  const handleSendShareMail = async () => {
    const mailText = `Hallo ${currentUserObject?.fullName}\n\nDas Unternehmen ${tenantData?.company?.companyName} würde gerne Zugriff auf dein Profil haben.\n\nWenn du damit einverstanden bist öffne bitte dein Profil, klicke auf 'Profil bearbeiten' und dann im Bereich 'Freigabe deines Profils' auf den Button:\nProfil freigeben\n\nDein Team von ${tenantData?.company?.companyName}`;

    // Send delete mail to us
    try {
      await axios({
        url: `${process.env.REACT_APP_API_URL}/sendTextMail`,
        method: 'post',
        data: {
          mailSubject: `${tenantData?.company?.companyName} - Anfrage Profilfreigabe`,
          mailTo: currentUserObject.email,
          mailText,
        },
        headers: {
          'content-type': 'application/json',
          Accept: 'application/json',
        },
      }).then(
        response => {
          console.log(response);
          toast.success(
            'Die Mail zur Beantragung der Profilfreischaltung wurde erfolgreich an das Mitglied geschickt!'
          );
        },
        error => {
          console.log(error);
          toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
        }
      );
    } catch (error) {
      console.log(error);
      toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
    }
  };

  const disableAccount = async (userUid: string, disableType: boolean) => {
    setIsDisablePending(true);
    const data = {
      tenant,
      userUid,
      disabledBoolean: disableType,
    };

    await axios({
      url: `${process.env.REACT_APP_API_URL ?? ''}/deactivate-tenant-account`,
      method: 'post',
      data,
      headers: {
        'content-type': 'application/json',
        Accept: 'application/json',
      },
    }).then(
      response => {
        console.log(response);
      },
      error => {
        console.log(error);
        toast.error('Es ist leider etwas schief gelaufen! Bitte kontaktieren Sie uns!');
        setIsDisablePending(false);
      }
    );

    setIsDisablePending(false);
    setDisableAccountPopupClass('hidden');
    setReactivateAccountPopupClass('hidden');
  };

  const handleOpenShareMail = ({ user }: { user: UserInfo }) => {
    const thisUserObject = user as any;

    setCurrentUserObject(thisUserObject);
    setSendShareMailClass('block');
  };

  const openDeleteByDateEditPopup = ({ user }: { user: UserInfo }) => {
    const thisUserObject = user as any;

    setCurrentUserObject(thisUserObject);
    setStartDate(null);
    setDeleteByDateEditClass('block');
  };

  const handleImpersonate = async ({ user }: { user: UserInfo }) => {
    const token = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
      userId: user.uid,
    });

    const impersonateToken = token.data?.token;

    if (impersonateToken) {
      const currentUserId = firebase.auth().currentUser?.uid;
      const currentUserToken = await axios.post(`${process.env.REACT_APP_API_URL ?? ''}/getUserToken`, {
        userId: currentUserId,
      });

      if (currentUserToken) {
        localStorage.setItem('currentUserToken', currentUserToken.data?.token);
        await firebase
          .auth()
          .signInWithCustomToken(impersonateToken)
          .catch(error => {
            console.log(error);
            return toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
          });
        window.location.reload();
      }
    }
  };

  const handleNoteSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNoteSearch(value);
  };

  const isUserActive = (user: UserInfo) => {
    return user.membership || user.lastLoginDate;
  };

  const handleChangeTrainer = async ({ user, trainer }: { user: UserInfo; trainer: string }) => {
    const trainerRef = firebase.firestore().collection(`tenants/${tenant}/users`).doc(trainer);
    if (user.type === 'invite') {
      await firebase
        .firestore()
        .collection('user-invitations')
        .doc(user?.uid)
        .update({ trainerRef, trainer: { newTrainer: trainer } });
    } else {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(user?.uid)
        .update({ trainerRef, trainer: { newTrainer: trainer } });
    }
    toast.success('Trainer wurde erfolgreich geändert');
  };

  const handleResetDeleteByDateQuery = async () => {
    setIsPendingDeleteByDate(true);
    if (currentUserObject.type !== undefined && currentUserObject.type === 'invite') {
      await firebase
        .firestore()
        .collection('user-invitations')
        .doc(currentUserObject?.uid)
        .update({ deleteDate: firebase.firestore.FieldValue.delete() });
    } else {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(currentUserObject?.uid)
        .update({ deleteDate: firebase.firestore.FieldValue.delete() });
    }

    await firebase.firestore().collection('tenantUserByDateDelete').doc(currentUserObject?.uid).delete();

    setIsPendingDeleteByDate(false);
    setDeleteByDateEditClass('hidden');
  };

  const handleChangeDeleteByDateQuery = async () => {
    setIsPendingDeleteByDate(true);

    if (startDate === null) {
      toast.error('Bitte wählen Sie ein Datum aus!');
      setIsPendingDeleteByDate(false);
    } else {
      const date = moment(startDate, 'DD.MM.YYYY');
      const unixTimestamp = date.startOf('day').unix();

      try {
        if (currentUserObject.type !== undefined && currentUserObject.type === 'invite') {
          await firebase
            .firestore()
            .collection('user-invitations')
            .doc(currentUserObject?.uid)
            .update({ deleteDate: unixTimestamp });
        } else {
          await firebase
            .firestore()
            .collection(`tenants/${tenant}/users`)
            .doc(currentUserObject?.uid)
            .update({ deleteDate: unixTimestamp });
        }

        await firebase
          .firestore()
          .collection('tenantUserByDateDelete')
          .doc(currentUserObject?.uid)
          .update({ nextDate: unixTimestamp });

        setIsPendingDeleteByDate(false);
        setDeleteByDateEditClass('hidden');
        toast.success('Lösch Datum erfolgreich geändert!');
      } catch (error) {
        console.log(error);
        setIsPendingDeleteByDate(false);
        toast.error('Es ist leider etwas schief gelaufen! Bitte versuche es erneut!');
      }
    }
  };

  const handleChangeLocation = async ({ user, locationNumber }: { user: UserInfo; locationNumber: number }) => {
    if (user.type === 'invite') {
      await firebase.firestore().collection('user-invitations').doc(user?.uid).update({ location: locationNumber });
    } else {
      await firebase
        .firestore()
        .collection(`tenants/${tenant}/users`)
        .doc(user?.uid)
        .update({ tenantLocation: locationNumber });
    }
    toast.success('Standort wurde erfolgreich geändert');
  };

  const handleChangeRole = async ({ user, roleId }: { user: UserInfo; roleId: number }) => {
    if (userData?.role === ROLE_COMPANY_ADMIN) {
      if (user.type === 'invite') {
        await firebase.firestore().collection('user-invitations').doc(user?.uid).update({ role: roleId });
      } else if (roleId === ROLE_LOCATION_ADMIN) {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(user?.uid).update({
          role: roleId,
          isTrainerUser: firebase.firestore.FieldValue.delete(),
          isAdminUser: firebase.firestore.FieldValue.delete(),
          isLocationAdminUser: true,
        });
      } else if (roleId === ROLE_TRAINER) {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(user?.uid).update({
          role: roleId,
          isTrainerUser: true,
          isAdminUser: firebase.firestore.FieldValue.delete(),
          isLocationAdminUser: firebase.firestore.FieldValue.delete(),
        });
      } else if (roleId === ROLE_COMPANY_ADMIN) {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(user?.uid).update({
          role: roleId,
          isTrainerUser: firebase.firestore.FieldValue.delete(),
          isAdminUser: true,
          isLocationAdminUser: firebase.firestore.FieldValue.delete(),
        });
      } else if (roleId === ROLE_MEMBER) {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(user?.uid).update({
          role: roleId,
          isTrainerUser: firebase.firestore.FieldValue.delete(),
          isAdminUser: firebase.firestore.FieldValue.delete(),
          isLocationAdminUser: firebase.firestore.FieldValue.delete(),
        });
      } else {
        await firebase.firestore().collection(`tenants/${tenant}/users`).doc(user?.uid).update({ role: roleId });
      }
      toast.success('Rolle wurde erfolgreich geändert');
    }
  };

  const remainTrialConnectDay = (seconds: number | undefined, trialDays: number) => {
    if (!seconds) return -1;
    const userCreated = moment.unix(seconds).utc();
    const diffDate = moment.utc().diff(userCreated, 'days');
    return trialDays - diffDate;
  };

  const getMemberData = () => {
    setIsPending(true);
    const memberList = [...(invitedMembers ?? []), ...(member ?? [])];

    const data: TableDataType[][] = memberList.map(item =>
      [
        {
          type: !isUserActive(item)
            ? 'text'
            : userData?.role === ROLE_TRAINER
            ? 'text'
            : item.uid === firebase.auth().currentUser?.uid
            ? 'text'
            : item.role === 3 || item.isAdminUser
            ? 'text'
            : userData?.role === ROLE_LOCATION_ADMIN && item.role === ROLE_LOCATION_ADMIN
            ? 'text'
            : item.accountDisabled !== undefined && item.accountDisabled === true
            ? 'nameWithAccountReactivate'
            : 'nameWithAccountDisable',
          value: item.fullName ?? '-',
          currentUser: item,
          clickFunction:
            item.accountDisabled !== undefined && item.accountDisabled === true
              ? openReactivatePopup
              : openDisablePopup,
        },
        {
          type: item.accountDisabled !== undefined && item.accountDisabled === true ? 'text-disabled' : 'text',
          value: item.email ?? '-',
        },
        {
          type:
            item.accountDisabled !== undefined && item.accountDisabled === true
              ? 'text-disabled'
              : item.membership?.paymentMethod === 'stripeConnect' && userData?.role === ROLE_COMPANY_ADMIN
              ? 'stripeConnect'
              : userData?.role === ROLE_COMPANY_ADMIN
              ? 'roleChange'
              : 'text',
          value:
            item.role === ROLE_TRAINER || item.isTrainerUser === true
              ? 'Trainer'
              : item.role === ROLE_COMPANY_ADMIN || item.isAdminUser === true
              ? 'Admin'
              : item.role === ROLE_LOCATION_ADMIN || item.isLocationAdminUser === true
              ? 'Gruppenleiter'
              : t(getRole(item.role) ?? '') ?? '-',
          currentUser: item,
          onChange: handleChangeRole,
        },
        {
          type:
            item.accountDisabled !== undefined && item.accountDisabled === true
              ? ''
              : userData?.role === ROLE_TRAINER
              ? 'text'
              : 'trainer',
          value: item.trainer?.fullName,
          currentUser: item,
          trainer: memberList.filter(
            memberItem => memberItem.role === ROLE_TRAINER || memberItem.isTrainerUser === true
          ),
          onChange: handleChangeTrainer,
        },
        {
          type:
            item.accountDisabled !== undefined && item.accountDisabled === true
              ? 'text-disabled'
              : userData?.role === ROLE_TRAINER
              ? 'text'
              : userData?.role === ROLE_LOCATION_ADMIN && item.role === ROLE_LOCATION_ADMIN
              ? 'text'
              : 'location',
          currentUser: item,
          onChange: handleChangeLocation,
          value:
            item.accountDisabled !== undefined &&
            item.accountDisabled === true &&
            tenantData?.settings?.locations === undefined
              ? 'hide'
              : item.accountDisabled !== undefined && item.accountDisabled === true
              ? ''
              : userData?.role === ROLE_LOCATION_ADMIN &&
                item.role === ROLE_LOCATION_ADMIN &&
                tenantData?.settings?.locations !== undefined &&
                tenantData?.settings?.locations?.length > 0
              ? getTenantLocationValueName(item.tenantLocation ?? 0)
              : !isUserActive(item) && userData?.role !== ROLE_TRAINER
              ? getLocationValue(item)
              : !isUserActive(item)
              ? getLocationValueName(item)
              : tenantData?.settings?.locations !== undefined &&
                tenantData?.settings?.locations?.length > 0 &&
                userData?.role !== ROLE_TRAINER
              ? item.tenantLocation
              : tenantData?.settings?.locations !== undefined && tenantData?.settings?.locations?.length > 0
              ? getTenantLocationValueName(item.tenantLocation ?? 0)
              : 'hide',
        },
        {
          type: item.accountDisabled !== undefined && item.accountDisabled === true ? 'text-disabled' : 'text',
          value:
            item.accountDisabled !== undefined && item.accountDisabled === true
              ? 'Gesperrt'
              : item.membership?.paymentMethod === 'stripeConnect' && item.membership?.type === 'canceled'
              ? `Gekündigt zum ${moment.unix(item.membership?.nextDate).format('DD.MM.YY')}`
              : item.membership?.paymentMethod === 'stripeConnect' && item.membership?.type === 'free'
              ? `Testphase (${remainTrialConnectDay(item.created?.seconds, item.membership?.nextDate)} Tage)`
              : isUserActive(item)
              ? 'Aktiv'
              : 'Eingeladen',
        },
        {
          type: item.accountDisabled !== undefined && item.accountDisabled === true ? 'text-disabled' : 'text',
          value: item.created?.toDate() ? moment(item.created?.toDate()).format('DD[.]MM[.]YY') : '-',
        },
        {
          type: item.accountDisabled !== undefined && item.accountDisabled === true ? 'text-disabled' : 'text',
          value: item.lastLoginDate?.toDate() ? moment(item.lastLoginDate?.toDate()).format('DD[.]MM[.]YY') : '-',
        },
        {
          type:
            item.accountDisabled !== undefined && item.accountDisabled === true
              ? ''
              : isUserActive(item)
              ? 'action'
              : 'actionResendMail',
          value: item,
          actions: [
            {
              icon: item.shareProfileType === 'anonym' || item.shareProfileType === undefined ? MailIcon : EyeIcon,
              disabled:
                !isUserActive(item) ||
                item.role === ROLE_COMPANY_ADMIN ||
                item.role === ROLE_TRAINER ||
                item.role === ROLE_LOCATION_ADMIN ||
                item.isTrainerUser === true ||
                item.isAdminUser === true,
              onClick:
                item.shareProfileType === 'anonym' || item.shareProfileType === undefined
                  ? handleOpenShareMail
                  : handleImpersonate,
            },
          ],
        },
        {
          type:
            item.toDelete !== undefined ? 'actionLoading' : item.deleteDate !== undefined ? 'deleteByDate' : 'action',
          value: item,
          actions: [
            {
              icon: TrashIcon,
              disabled:
                item.uid === firebase.auth().currentUser?.uid
                  ? true
                  : item.role === ROLE_COMPANY_ADMIN || item.isAdminUser
                  ? true
                  : userData?.role === ROLE_LOCATION_ADMIN && item.role === ROLE_LOCATION_ADMIN
                  ? true
                  : userData?.role === ROLE_COMPANY_ADMIN
                  ? false
                  : userData?.role !== ROLE_LOCATION_ADMIN,
              onClick: item.deleteDate !== undefined ? openDeleteByDateEditPopup : handleDelete,
            },
          ],
        },
        {
          type: item.accountDisabled !== undefined && item.accountDisabled === true ? '' : 'action',
          value: item,
          actions: [
            {
              icon: ChatIcon,
              disabled:
                !isUserActive(item) ||
                item.uid === firebase.auth().currentUser?.uid ||
                item.role !== ROLE_MEMBER ||
                item.isAdminUser ||
                item.isTrainerUser,
              onClick: handleNote,
            },
          ],
        },
      ].filter(subitem => subitem.value !== 'hide')
    );

    setMemberData(data);
    setIsPending(false);
  };

  useEffect(() => {
    getMemberData();
    // eslint-disable-next-line
  }, [member, invitedMembers]);

  useEffect(() => {
    const thisTrainerOptions: any =
      [...(invitedMembers ?? []), ...(member ?? [])]
        ?.filter(item => item.role === ROLE_TRAINER || item.isTrainerUser === true)
        .map(item => {
          return { value: item.uid, label: item.fullName };
        }) ?? [];

    thisTrainerOptions.unshift({ value: 0, label: 'Alle' });

    setTrainerOptions(thisTrainerOptions);
    // eslint-disable-next-line
  }, [invitedMembers, member]);

  useEffect(() => {
    const unsubscribeInvitations = firebase
      .firestore()
      .collection('user-invitations')
      .where('tenant', '==', tenant)
      .orderBy('created', 'desc')
      .onSnapshot(
        snapshot => {
          const inviteData: any = [];

          snapshot.forEach(doc => {
            inviteData.push({ ...doc.data(), uid: doc.id, type: 'invite' });
          });

          setInvitedMembers(inviteData);
        },
        error => {
          console.error('Error getting user-invitations for tenant: ', error);
        }
      );

    return unsubscribeInvitations;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection(`tenants/${tenant}/users`)
      .orderBy('created', 'desc')
      .onSnapshot(
        snapshot => {
          const users: any = [];

          snapshot.forEach(doc => {
            users.push({ ...doc.data(), uid: doc.id });
          });

          if (
            (userData?.role === ROLE_TRAINER || userData?.role === ROLE_LOCATION_ADMIN) &&
            userData?.tenantLocation !== undefined &&
            initialTrainerLoad === false
          ) {
            setInitialTrainerLoad(true);
          }

          setMember(users);
        },
        error => {
          console.error('Error getting all user from tenant: ', error);
        }
      );
    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (initialTrainerLoad === true && userData?.tenantLocation !== undefined) {
      const getLocation = locationOptions.filter((item: any) => item.value === userData?.tenantLocation);

      if (getLocation.length > 0) {
        setCurrentLocation(userData?.tenantLocation);
      }
    }
    // eslint-disable-next-line
  }, [initialTrainerLoad]);

  useEffect(() => {
    const getTrainer = trainerOptions.filter((item: any) => item.value === authContext.user?.uid);

    if (getTrainer.length > 0 && authContext.user?.uid) {
      // setCurrentTrainer(authContext.user?.uid);
    }
  }, [trainerOptions]);

  const filteredMemberDataMemo = useMemo(() => {
    if (!tenantData?.settings?.locations || tenantData.settings.locations.length === 0) {
      const filteredMembers = memberData.filter(item => {
        const fullName = item[0]?.value?.toLowerCase();
        const email = item[1]?.value?.toLowerCase();
        const role = item[2]?.value?.toLowerCase();
        const status = item[4]?.value?.toLowerCase();
        const created = item[5]?.value?.toLowerCase();
        const lastLogin = item[6]?.value?.toLowerCase();

        return (
          (fullName.includes(search.toLowerCase()) ||
            email.includes(search.toLowerCase()) ||
            role.includes(search.toLowerCase()) ||
            status.includes(search.toLowerCase()) ||
            created.includes(search.toLowerCase()) ||
            lastLogin.includes(search.toLowerCase())) &&
          (getRoleByLabel(item[2].value) === currentFilter ||
            currentFilter === undefined ||
            (currentFilter === 4 && item[2].currentUser.isTrainerUser === true)) &&
          (item[2].currentUser?.tenantLocation === currentLocation ||
            item[2].currentUser?.location === currentLocation ||
            currentLocation === undefined) &&
          (item[2].currentUser?.trainer?.uid === currentTrainer || currentTrainer === undefined) &&
          ((filterInvitedMembers && item[2].currentUser?.type === 'invite') || filterInvitedMembers === undefined) &&
          ((filterActiveMembers &&
            (item[2].currentUser?.membership !== undefined || item[2].currentUser?.isAdminUser) &&
            (item[2].currentUser?.accountDisabled === undefined || item[2].currentUser?.accountDisabled === false)) ||
            filterActiveMembers === undefined) &&
          ((disabledInvitedMembers && item[2].currentUser?.accountDisabled === true) ||
            disabledInvitedMembers === undefined)
        );
      });

      setCurrentDisplayedMemberCount(filteredMembers.length);
      const slicedMembers = filteredMembers.slice(currentPageIndex, currentPageIndex + currentResultPerPage);
      return slicedMembers;
    }

    const filteredMembersWithLocation = memberData.filter(item => {
      const fullName = item[0]?.value?.toLowerCase();
      const email = item[1]?.value?.toLowerCase();
      const role = item[2]?.value?.toLowerCase();
      const status = item[5]?.value?.toLowerCase();
      const created = item[6]?.value?.toLowerCase();
      const lastLogin = item[7]?.value?.toLowerCase();

      return (
        (fullName.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase()) ||
          role.includes(search.toLowerCase()) ||
          status.includes(search.toLowerCase()) ||
          created.includes(search.toLowerCase()) ||
          lastLogin.includes(search.toLowerCase())) &&
        (getRoleByLabel(item[2].value) === currentFilter ||
          currentFilter === undefined ||
          (currentFilter === 4 && item[2].currentUser.isTrainerUser === true)) &&
        (item[2].currentUser?.tenantLocation === currentLocation ||
          item[2].currentUser?.location === currentLocation ||
          currentLocation === undefined) &&
        (item[2].currentUser?.trainer?.uid === currentTrainer || currentTrainer === undefined) &&
        ((filterInvitedMembers && item[2].currentUser?.type === 'invite') || filterInvitedMembers === undefined) &&
        ((filterActiveMembers &&
          (item[2].currentUser?.membership !== undefined || item[2].currentUser?.isAdminUser) &&
          (item[2].currentUser?.accountDisabled === undefined || item[2].currentUser?.accountDisabled === false)) ||
          filterActiveMembers === undefined) &&
        ((disabledInvitedMembers && item[2].currentUser?.accountDisabled === true) ||
          disabledInvitedMembers === undefined)
      );
    });

    setCurrentDisplayedMemberCount(filteredMembersWithLocation.length);
    const slicedMembersWithLocation = filteredMembersWithLocation.slice(
      currentPageIndex,
      currentPageIndex + currentResultPerPage
    );
    return slicedMembersWithLocation;
  }, [
    search,
    memberData,
    currentFilter,
    currentLocation,
    currentTrainer,
    filterInvitedMembers,
    filterActiveMembers,
    disabledInvitedMembers,
    tenantData,
    currentPageIndex,
    currentResultPerPage,
  ]);

  const filteredMemberDataForLengthMemo = useMemo(() => {
    if (!tenantData?.settings?.locations || tenantData.settings.locations.length === 0) {
      return memberData.filter(item => {
        const fullName = item[0]?.value?.toLowerCase();
        const email = item[1]?.value?.toLowerCase();
        const role = item[2]?.value?.toLowerCase();
        const status = item[4]?.value?.toLowerCase();
        const created = item[5]?.value?.toLowerCase();
        const lastLogin = item[6]?.value?.toLowerCase();

        return (
          (fullName.includes(search.toLowerCase()) ||
            email.includes(search.toLowerCase()) ||
            role.includes(search.toLowerCase()) ||
            status.includes(search.toLowerCase()) ||
            created.includes(search.toLowerCase()) ||
            lastLogin.includes(search.toLowerCase())) &&
          (item[2].currentUser?.trainer?.uid === currentTrainer || currentTrainer === undefined) &&
          (item[2].currentUser?.tenantLocation === currentLocation ||
            item[2].currentUser?.location === currentLocation ||
            currentLocation === undefined)
        );
      });
    }

    return memberData.filter(item => {
      const fullName = item[0]?.value?.toLowerCase();
      const email = item[1]?.value?.toLowerCase();
      const role = item[2]?.value?.toLowerCase();
      const status = item[5]?.value?.toLowerCase();
      const created = item[6]?.value?.toLowerCase();
      const lastLogin = item[7]?.value?.toLowerCase();

      return (
        (fullName.includes(search.toLowerCase()) ||
          email.includes(search.toLowerCase()) ||
          role.includes(search.toLowerCase()) ||
          status.includes(search.toLowerCase()) ||
          created.includes(search.toLowerCase()) ||
          lastLogin.includes(search.toLowerCase())) &&
        (item[2].currentUser?.trainer?.uid === currentTrainer || currentTrainer === undefined) &&
        (item[2].currentUser?.tenantLocation === currentLocation ||
          item[2].currentUser?.location === currentLocation ||
          currentLocation === undefined)
      );
    });
  }, [search, memberData, currentTrainer, currentLocation, tenantData]);

  useEffect(() => {
    setFilteredMemberData(filteredMemberDataMemo);
    setFilteredMemberDataForLength(filteredMemberDataForLengthMemo);

    const getPageSize = Math.ceil(currentDisplayedMemberCount / currentResultPerPage);

    const array = [];
    const maxIterations = Math.min(getPageSize, 20);
    for (let i = 1; i <= maxIterations; i += 1) {
      array.push({ value: i, label: i.toString() });
    }

    if (getPageSize > 20) {
      array.push({ value: getPageSize, label: getPageSize.toString() });
    }

    setPagesOptions(array);
  }, [filteredMemberDataMemo, filteredMemberDataForLengthMemo]);

  useEffect(() => {
    const filteredNoteList = selectedUserInitialCommentList.filter(
      (element: any) =>
        element.comment.toLowerCase().includes(noteSearch.toLowerCase()) ||
        element.date.toLowerCase().includes(noteSearch.toLowerCase())
    );

    setSelectedUserCommentList(filteredNoteList);
  }, [noteSearch]);

  const filterMemberDataByRole = (role: number | undefined) => {
    setCurrentPageIndex(0);
    setCurrentPageForDropDown(0);
    setDisabledInvitedMembers(undefined);
    setFilterInvitedMembers(undefined);
    setFilterActiveMembers(undefined);
    setRefreshFilter(refreshFilter + 1);
    if (!role) {
      setCurrentFilter(undefined);
      return setFilteredMemberData(memberData);
    }
    if (role === 4) {
      const filteredData = memberData.filter(item => {
        return getRoleByLabel(item[2].value) === role || item[2].currentUser.isTrainerUser === true;
      });
      setCurrentFilter(role);
      return setFilteredMemberData(filteredData);
    }
    const filteredData = memberData.filter(item => {
      return getRoleByLabel(item[2].value) === role;
    });
    setCurrentFilter(role);
    return setFilteredMemberData(filteredData);
  };

  const filterDisabledMembers = () => {
    setFilterInvitedMembers(undefined);
    setFilterActiveMembers(undefined);
    const filteredData = memberData.filter(item => {
      return item[2].currentUser?.accountDisabled === true;
    });
    setDisabledInvitedMembers(true);
    setCurrentFilter(undefined);
    setRefreshFilter(refreshFilter + 1);
    return setFilteredMemberData(filteredData);
  };

  const scrollLeft = () => {
    document.getElementById('customerTable')!.scrollLeft = 2000;
  };

  const scrollRight = () => {
    document.getElementById('customerTable')!.scrollLeft = -2000;
  };

  const pageNext = () => {
    setCurrentPageIndex(currentPageIndex + currentResultPerPage);
    setCurrentPageForDropDown((currentPageIndex + currentResultPerPage) / currentResultPerPage);

    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);
  };

  const pagePrevious = () => {
    setCurrentPageIndex(currentPageIndex - currentResultPerPage);
    setCurrentPageForDropDown((currentPageIndex - currentResultPerPage) / currentResultPerPage);

    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);
  };

  const setBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.add('keyboard-open');
    }
  };

  const removeBodyClassForMobileSafari = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      document.body.classList.remove('keyboard-open');
    }
  };

  const filterInvitedMemberData = () => {
    setDisabledInvitedMembers(undefined);
    const filteredData = memberData.filter(item => {
      return item[2].currentUser?.type === 'invite';
    });
    setFilterInvitedMembers(true);
    setFilterActiveMembers(undefined);
    setCurrentFilter(undefined);
    setRefreshFilter(refreshFilter + 1);
    return setFilteredMemberData(filteredData);
  };

  const filteractiveMemberData = () => {
    setDisabledInvitedMembers(undefined);
    const filteredData = memberData.filter(item => {
      return (
        (item[2].currentUser?.membership !== undefined || item[2].currentUser?.isAdminUser) &&
        (item[2].currentUser?.accountDisabled === undefined || item[2].currentUser?.accountDisabled === false)
      );
    });
    setFilterInvitedMembers(undefined);
    setCurrentFilter(undefined);
    setFilterActiveMembers(true);
    setRefreshFilter(refreshFilter + 1);
    return setFilteredMemberData(filteredData);
  };

  const changeResultsPerPage = (value: any) => {
    setCurrentPageIndex(0);
    setCurrentPageForDropDown(0);

    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);

    setCurrentResultPerPage(value.value);
  };

  const changeResultPage = (value: any) => {
    setCurrentPageIndex((parseFloat(value.value) - 2) * currentResultPerPage + currentResultPerPage);
    setCurrentPageForDropDown(
      ((parseFloat(value.value) - 2) * currentResultPerPage + currentResultPerPage) / currentResultPerPage
    );

    setTimeout(() => {
      document.getElementById('mainContainer')?.scrollTo(0, 0);
    }, 1);
  };

  const filterByLocation = (value: any) => {
    setCurrentPageIndex(0);
    setCurrentPageForDropDown(0);
    if (value.value === 0) {
      setCurrentLocation(undefined);
      setFilteredMemberData(memberData);
      setFilteredMemberDataForLength(memberData);

      if (trainerSelectRef.current) {
        trainerSelectRef.current.clearValue();
        setCurrentTrainer(undefined);
      }

      const newTrainerOptions: any =
        [...(invitedMembers ?? []), ...(member ?? [])]
          ?.filter(item => item.role === ROLE_TRAINER || item.isTrainerUser === true)
          .map(item => {
            return { value: item.uid, label: item.fullName };
          }) ?? [];

      newTrainerOptions.unshift({ value: 0, label: 'Alle' });

      setTrainerOptions(newTrainerOptions);
      setRefreshFilter(refreshFilter + 1);
    } else {
      setCurrentLocation(value.value);
      const filteredData = memberData.filter(item => {
        return item[2].currentUser?.tenantLocation === value.value;
      });

      if (trainerSelectRef.current) {
        trainerSelectRef.current.clearValue();
        setCurrentTrainer(undefined);
      }

      const newTrainerOptions: any =
        [...(invitedMembers ?? []), ...(member ?? [])]
          ?.filter(
            item => (item.role === ROLE_TRAINER || item.isTrainerUser === true) && item.tenantLocation === value.value
          )
          .map(item => {
            return { value: item.uid, label: item.fullName };
          }) ?? [];

      newTrainerOptions.unshift({ value: 0, label: 'Alle' });

      setTrainerOptions(newTrainerOptions);

      setFilteredMemberData(filteredData);
      setFilteredMemberDataForLength(filteredData);
      setRefreshFilter(refreshFilter + 1);
    }
  };

  const filterByTrainer = (value: any) => {
    setCurrentPageIndex(0);
    setCurrentPageForDropDown(0);
    if (value === undefined || value?.value === undefined || value.value === 0) {
      setCurrentTrainer(undefined);
      setFilteredMemberData(memberData);
      setFilteredMemberDataForLength(memberData);
      setRefreshFilter(refreshFilter + 1);
    } else {
      setCurrentTrainer(value.value);
      const filteredData = memberData.filter(item => {
        return item[2].currentUser?.trainer?.uid === value.value;
      });

      setFilteredMemberData(filteredData);
      setFilteredMemberDataForLength(filteredData);
      setRefreshFilter(refreshFilter + 1);
    }
  };

  const [selectedOption, setSelectedOption] = useState('direct');

  const CustomDateInput = (props: React.HTMLProps<HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => {
    return (
      <label>
        <div className="flex w-full">
          <div className="flex-1">
            <input {...props} />
          </div>
          <div className="ml-10 my-auto">
            <CalendarIcon width={25} height={25} className="text-accentColor mx-auto cursor-pointer" />
          </div>
        </div>
      </label>
    );
  };

  return (
    <>
      {selectedUser && notePopupClass === 'block' && (
        <Overlay
          setOverlayClass={setNotePopupClass}
          overlayClass={notePopupClass}
          headline="Kommentar hinzufügen"
          icon={<ChatIcon width={25} height={25} className="text-accentColor mx-auto" />}
        >
          <div>
            {selectedUserInitialCommentList.length > 0 && (
              <CustomInput
                type="text"
                placeholder="Notiz suchen"
                name="search"
                onChange={handleNoteSearch}
                className="w-full mt-30"
              />
            )}
            {selectedUserCommentList.length > 0 && (
              <div>
                <div className="pt-30">
                  {selectedUserCommentList.map((item: any, index: number) => (
                    <div key={index} className="pb-10">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-20 font-medium text-left rounded-20 bg-lightGray focus:outline-none mb-3">
                              <span>{item.date}</span>
                              <ChevronDownIcon
                                className={`${open ? 'transform rotate-180' : ''} w-35 h-35 text-accentColor`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4">
                              <textarea
                                defaultValue={item.comment ?? ''}
                                className="w-full h-300 text-textColor bg-transparent border-textColor border rounded-20 p-10"
                                onChange={e => setComment(e.target.value)}
                                onFocus={setBodyClassForMobileSafari}
                                onBlur={removeBodyClassForMobileSafari}
                              />
                              <div className="flex justify-between pt-10 pb-20">
                                <Button onClick={() => updateNote(item.dateSeconds)} className="py-10">
                                  Speichern
                                </Button>
                                <Button onClick={() => deleteNote(item.dateSeconds)} className="py-10">
                                  Notiz löschen
                                </Button>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div>
              <div className="pt-30 font-extralight text-base">
                <div className="pb-10">Hier kannst du einen neuen Kommentar hinzufügen.</div>
                <textarea
                  className="w-full h-300 text-textColor bg-transparent border-textColor border rounded-20 p-10"
                  onChange={e => setComment(e.target.value)}
                  onFocus={setBodyClassForMobileSafari}
                  onBlur={removeBodyClassForMobileSafari}
                />
              </div>

              <div className="pt-30 flex gap-30 pb-40">
                <div>
                  <Button
                    className="w-full"
                    onClick={() => {
                      saveNote();
                    }}
                  >
                    Speichern
                  </Button>
                </div>
                <div>
                  <Button
                    className="w-full"
                    buttonStyle="white"
                    onClick={() => {
                      setSelectedUser({} as UserInfo);
                      setNotePopupClass('hidden');
                      setComment('');
                    }}
                  >
                    Abbrechen
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Overlay>
      )}
      {selectedUser && deleteProfilePopupClass === 'block' && (
        <Overlay
          setOverlayClass={setDeleteProfilePopupClass}
          overlayClass={deleteProfilePopupClass}
          headline="Mitglied löschen"
          icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
        >
          {selectedUser.membership?.paymentMethod === 'stripeConnect' ? (
            <div className="pt-30 font-extralight text-base">
              <div>
                Dieses Mitglied wurde über Tortija Billing registriert und hat noch ein aktives Abonnement oder ein
                Abonnement, welches noch nicht abgelaufen ist.
              </div>
              <div className="pt-10">
                Bitte kündigen Sie als erstes das Abonnement über Stripe. Das Mitglied wird bei gekündigten Abonnement
                automatisch nach Ablauf seines Abonnement-Zeitraums gelöscht.
              </div>
              <div className="pt-30 flex gap-30 pb-40">
                <Link
                  to={{
                    pathname: `https://dashboard.stripe.com/${
                      tenantData?.stripeConnect?.stripeConnectAccountId ?? ''
                    }/test/subscriptions/${selectedUser?.membership?.id}`,
                  }}
                  target="_blank"
                  type="button"
                  className="my-auto"
                >
                  <Button>Zum Abo in Stripe springen</Button>
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <div className="pt-30 font-extralight text-base">
                <div>Wollen Sie das ausgewählte Mitglied wirklich löschen?</div>
                <div>Alle gespeicherten Daten werden dabei gelöscht!</div>
                <div className="font-bold pt-15">Wann soll das Mitglied gelöscht werden?</div>
                <div
                  className={`rounded-md bg-${
                    selectedOption === 'direct' ? 'accentColor' : 'lightGray'
                  } p-10 mt-10 cursor-pointer`}
                  onClick={() => setSelectedOption('direct')}
                  aria-hidden="true"
                >
                  <div className="flex justify-between">
                    <div>
                      <div
                        className={
                          selectedOption === 'date'
                            ? 'font-semibold text-textColor'
                            : 'font-semibold text-buttonTextColor'
                        }
                      >
                        Sofort
                      </div>
                      <div
                        className={
                          selectedOption === 'date'
                            ? 'font-extralight text-14 text-textColor'
                            : 'font-extralight text-14 text-buttonTextColor'
                        }
                      >
                        Das Mitglied wird sofort gelöscht!
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={`rounded-md bg-${
                    selectedOption === 'date' ? 'accentColor' : 'lightGray'
                  } p-10 mt-15 cursor-pointer`}
                  onClick={() => setSelectedOption('date')}
                  aria-hidden="true"
                >
                  <div className="flex justify-between">
                    <div>
                      <div
                        className={
                          selectedOption === 'date'
                            ? 'font-semibold text-buttonTextColor'
                            : 'font-semibold text-textColor'
                        }
                      >
                        Zu einem bestimmten Datum
                      </div>
                      <div
                        className={
                          selectedOption === 'date'
                            ? 'font-extralight text-14 text-buttonTextColor'
                            : 'font-extralight text-14 text-textColor'
                        }
                      >
                        Das Mitglied wird zu einem bestimmten Datum gelöscht!
                      </div>
                    </div>
                  </div>
                </div>

                {selectedOption === 'date' && (
                  <div>
                    <div className="pt-10 font-extralight">
                      Bitte wählen Sie ein Datum aus, wann das Mitglied gelöscht werden soll.
                    </div>
                    <div>Einmal pro Tag wird geprüft ob Mitglieder gelöscht werden müssen.</div>
                    <div className="pt-10">
                      <DatePicker
                        className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        locale="de"
                        dateFormat="dd.MM.yyyy"
                        minDate={moment().toDate()}
                        withPortal
                        customInput={React.createElement(React.forwardRef(CustomDateInput))}
                        calendarStartDay={1}
                        onFocus={e => e.target.blur()}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="pt-30 flex gap-30 pb-40">
                <div>
                  {selectedOption === 'direct' ? (
                    <Button
                      className="w-full"
                      onClick={() => {
                        deleteUserAccount();
                        setDeleteProfilePopupClass('hidden');
                      }}
                    >
                      Mitglied löschen
                    </Button>
                  ) : (
                    <Button
                      isPending={isPendingDeleteByDate}
                      disabled={isPendingDeleteByDate}
                      className="w-full"
                      onClick={() => {
                        deleteUserByDate();
                      }}
                    >
                      Löschung planen
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    className="w-full"
                    buttonStyle="white"
                    onClick={() => {
                      setSelectedUser({} as UserInfo);
                      setDeleteProfilePopupClass('hidden');
                    }}
                  >
                    Abbrechen
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Overlay>
      )}

      <Overlay
        setOverlayClass={setSendShareMailClass}
        overlayClass={sendShareMailClass}
        headline="Profilfreigabe anfordern"
        icon={<MailIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pb-10">
              Möchten Sie{' '}
              <span className="font-bold">
                {currentUserObject?.fullName !== undefined && currentUserObject?.fullName}
              </span>{' '}
              eine Mail schicken und ihn bitten sein Profil freizugeben?
            </div>
          </div>
          <div className="pt-30 flex gap-30 pb-40">
            <div>
              <Button
                className="w-full"
                onClick={() => {
                  handleSendShareMail();
                  setSendShareMailClass('hidden');
                }}
              >
                Freigabe anfordern
              </Button>
            </div>

            <div>
              <Button
                className="w-full"
                buttonStyle="white"
                onClick={() => {
                  setSendShareMailClass('hidden');
                }}
              >
                Abbrechen
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        setOverlayClass={setDeleteByDateEditClass}
        overlayClass={deleteByDateEditClass}
        headline="Geplante Löschung anpassen"
        icon={<TrashIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            Name des Mitglieds: <span className="font-bold">{currentUserObject?.fullName}</span>
          </div>
          <div className="pt-5 font-extralight text-base">
            Lösch Datum:{' '}
            <span className="font-bold">
              {currentUserObject?.deleteDate !== undefined
                ? moment.unix(currentUserObject?.deleteDate).format('DD.MM.YYYY')
                : ''}
            </span>
          </div>
          <div className="pt-20 font-extralight">
            Wenn Sie das Lösch Datum ändern wollen, geben Sie hier bitte ein neues Datum ein:
          </div>
          <div className="pt-10">
            <DatePicker
              className="w-full appearance-none block py-1 px-2 rounded-md text-base placeholder-gray-400 focus:outline-none bg-lightGray bg-opacity-20 text-textColor border-solid border border-textColor border-opacity-30"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              locale="de"
              dateFormat="dd.MM.yyyy"
              minDate={moment().toDate()}
              withPortal
              customInput={React.createElement(React.forwardRef(CustomDateInput))}
              calendarStartDay={1}
              onFocus={e => e.target.blur()}
            />
          </div>
          <div className="pt-20 font-extralight">
            Wenn Sie den Löschvorgang rückgängig machen wollen und das Mitglied nicht mehr löschen wollen, klicken Sie
            bitte auf den Button "Löschvorgang rückgängig"!
          </div>
          <div className="pt-30 flex gap-30 pb-40">
            <div>
              <Button
                className="w-full"
                isPending={isPendingDeleteByDate}
                disabled={isPendingDeleteByDate}
                onClick={() => {
                  handleChangeDeleteByDateQuery();
                }}
              >
                Datum ändern
              </Button>
            </div>

            <div>
              <Button
                className="w-full"
                isPending={isPendingDeleteByDate}
                disabled={isPendingDeleteByDate}
                onClick={() => {
                  handleResetDeleteByDateQuery();
                }}
              >
                Löschvorgang rückgängig
              </Button>
            </div>
          </div>
        </div>
      </Overlay>

      <Overlay
        setOverlayClass={setDisableAccountPopupClass}
        overlayClass={disableAccountPopupClass}
        headline="Account sperren"
        icon={<LockClosedIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pb-10">
              <div className="pb-10">Möchten Sie folgenden Account wirklich sperren?</div>
              <div className="pb-10">Sie können den Account jederzeit reaktivieren.</div>
              <div className="flex pb-5">
                <div className="font-bold w-60">Name:</div>
                <div>{currentUserObject?.fullName !== undefined && currentUserObject?.fullName}</div>
              </div>
              <div className="flex">
                <div className="font-bold w-60">Email:</div>
                <div>{currentUserObject?.email !== undefined && currentUserObject?.email}</div>
              </div>
            </div>
          </div>
          <div className="pt-30 flex gap-30 pb-40">
            <div>
              <Button
                className="w-full"
                isPending={isDisablePending}
                onClick={() => {
                  disableAccount(currentUserObject?.uid, true);
                }}
              >
                Account sperren
              </Button>
            </div>

            {!isDisablePending && (
              <div>
                <Button
                  className="w-full"
                  buttonStyle="white"
                  onClick={() => {
                    setDisableAccountPopupClass('hidden');
                  }}
                >
                  Abbrechen
                </Button>
              </div>
            )}
          </div>
        </div>
      </Overlay>

      <Overlay
        setOverlayClass={setReactivateAccountPopupClass}
        overlayClass={reactivateAccountPopupClass}
        headline="Account reaktivieren"
        icon={<LockClosedIcon width={25} height={25} className="text-accentColor mx-auto" />}
      >
        <div>
          <div className="pt-30 font-extralight text-base">
            <div className="pb-10">
              <div className="pb-10">Möchten Sie folgenden Account wirklich reaktivieren?</div>
              <div className="flex pb-5">
                <div className="font-bold w-60">Name:</div>
                <div>{currentUserObject?.fullName !== undefined && currentUserObject?.fullName}</div>
              </div>
              <div className="flex">
                <div className="font-bold w-60">Email:</div>
                <div>{currentUserObject?.email !== undefined && currentUserObject?.email}</div>
              </div>
            </div>
          </div>
          <div className="pt-30 flex gap-30 pb-40">
            <div>
              <Button
                className="w-full"
                isPending={isDisablePending}
                onClick={() => {
                  disableAccount(currentUserObject?.uid, false);
                }}
              >
                Account reaktivieren
              </Button>
            </div>

            {!isDisablePending && (
              <div>
                <Button
                  className="w-full"
                  buttonStyle="white"
                  onClick={() => {
                    setReactivateAccountPopupClass('hidden');
                  }}
                >
                  Abbrechen
                </Button>
              </div>
            )}
          </div>
        </div>
      </Overlay>

      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.buttons}>
            <Headline level={1}>{t('Member Overview')}</Headline>
            <div className="flex gap-20 my-auto">
              {(userData?.role === ROLE_COMPANY_ADMIN || userData?.role === ROLE_LOCATION_ADMIN) && (
                <Link to="/member/import" type="button" className="my-auto">
                  <div className="p-2">
                    <Button>Mitglieder importieren</Button>
                  </div>
                </Link>
              )}

              <Link to="/member/add" type="button" className="my-auto">
                <div className="flex space-x-10 cursor-pointer rounded-md p-2 border-transparent border-2 hover:border-accentColor">
                  <div className="my-auto">Neues Mitglied</div>
                  <PlusIcon width={28} height={28} className="text-accentColor" />
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className={readMore === false ? styles.textTruncate : ''}>
          <p className="mb-10">
            In der unteren Tabelle werden Ihnen ihre Mitglieder / Kunden und Trainer angezeigt. Über das "+" Icon können
            Sie neue Mitglieder und Trainer einladen. Diese bekommen dann eine E-Mail mit einem Einladungslink und
            können sich im Anschluss registrieren. Registrierte Mitglieder werden dann als "Aktiv" angezeigt.
          </p>
          <p className="mb-10">
            Zusätzlich können Sie Kommentare für jedes Mitglied hinzufügen oder das Mitglied löschen.
          </p>
          <p className="mb-10">
            Über das "Auge-Icon" können Sie sich mit dem Account des Mitglieds einloggen und diesen Lebensmittel
            eintragen bzw. das Ernährungsprotokoll einsehen und verändern, wenn diese ihr Profil freigegeben haben.
          </p>
          <p>
            Hat das Mitglied das Profil nicht freigegeben, wird ein "Brief" Icon angezeigt. Durch Klick auf dieses Icon,
            können Sie dem Mitglied eine automatisierte E-Mail schicken und ihn bitten, sein Profil freizugeben!
          </p>
        </div>
        {readMore === false ? (
          <div
            className="pb-20 pt-10 flex space-x-1 flex-wrap cursor-pointer"
            onClick={() => setReadMore(true)}
            aria-hidden="true"
          >
            <div className="my-auto">
              <ChevronRightIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
            </div>
            <div className="hover:underline">Mehr erfahren</div>
          </div>
        ) : (
          <div
            className="pb-20 pt-10 flex space-x-1 flex-wrap cursor-pointer"
            onClick={() => setReadMore(false)}
            aria-hidden="true"
          >
            <div className="my-auto">
              <ChevronRightIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
            </div>
            <div className="hover:underline">Zurück</div>
          </div>
        )}

        {userData?.role === ROLE_COMPANY_ADMIN && (
          <div>
            <div className="font-bold text-18">Standorte</div>
            <div>
              Sie haben mehrere Standorte und möchten Ihren Mitgliedern einen Standort zuordnen? In den Einstellungen
              können Sie neue Standorte anlegen!
            </div>
            <div className="flex">
              <Link
                to={{
                  pathname: '/company-settings',
                }}
              >
                <div className="pb-20 pt-10 flex space-x-1 flex-wrap cursor-pointer">
                  <div className="my-auto">
                    <ChevronRightIcon width={20} height={20} className="text-accentColor mx-auto cursor-pointer" />
                  </div>
                  <div className="hover:underline">Zu den Einstellungen</div>
                </div>
              </Link>
            </div>
          </div>
        )}

        {isPending || !memberData ? (
          <Table header={Array.from(new Array(4))} body={skeletonTable} className="w-full" isSkeleton />
        ) : (
          <>
            <div className="flex flex-wrap gap-20 mt-15">
              <div>
                <div className="block text-sm font-regular text-textColor opacity-50 mb-1">Mitglied suchen</div>
                <CustomInput
                  type="text"
                  placeholder="Mitglied suchen"
                  name="search"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleSearchDebounced(e.target.value)}
                  className="w-250"
                />
              </div>
              {tenantData?.settings?.locations !== undefined &&
                tenantData?.settings?.locations.length > 0 &&
                (userData?.role === ROLE_COMPANY_ADMIN ||
                  userData?.role === ROLE_LOCATION_ADMIN ||
                  ((userData?.role === ROLE_TRAINER || userData?.role === ROLE_LOCATION_ADMIN) &&
                    (tenantData?.settings?.functions?.trainerLocation === undefined ||
                      tenantData?.settings?.functions?.trainerLocation === true))) && (
                  <div className="w-full md:w-250">
                    <CustomSelect
                      name="location"
                      dropDownOption={locationOptions}
                      label="Standort filtern"
                      isSearchable
                      isFullSize
                      onChange={filterByLocation}
                      defaultValue={
                        (userData?.role === ROLE_TRAINER || userData?.role === ROLE_LOCATION_ADMIN) &&
                        userData?.tenantLocation !== undefined
                          ? locationOptions.filter((item: any) => item.value === userData?.tenantLocation)
                          : locationOptions.filter((item: any) => item.value === 0)
                      }
                      className="z-60"
                    />
                  </div>
                )}
              {(userData?.role === ROLE_TRAINER ||
                userData?.role === ROLE_COMPANY_ADMIN ||
                userData?.role === ROLE_LOCATION_ADMIN) &&
                trainerOptions.length > 1 && (
                  <div className="w-full md:w-250">
                    <CustomSelect
                      name="trainer"
                      dropDownOption={trainerOptions}
                      label="Trainer filtern"
                      isFullSize
                      onChange={filterByTrainer}
                      defaultValue={trainerOptions.filter((item: any) => item.value === 0)}
                      className="z-60"
                      thisRef={trainerSelectRef}
                    />
                  </div>
                )}
            </div>
            <div className="flex gap-x-15 mb-10 flex-wrap desktop:sticky top-70 z-50 pt-20 bg-bgColor pb-20">
              <div className="flex justify-between w-full gap-x-15">
                <div className="flex gap-x-15 mb-10 flex-wrap my-auto">
                  <button
                    type="button"
                    onClick={() => filterMemberDataByRole(undefined)}
                    className={classNames('focus:outline-none opacity-50', {
                      'opacity-100 font-bold':
                        currentFilter === undefined &&
                        !filterInvitedMembers &&
                        !disabledInvitedMembers &&
                        !filterActiveMembers,
                    })}
                  >
                    Alle ({filteredMemberDataForLength.length})
                  </button>
                  <button
                    type="button"
                    onClick={() => filterMemberDataByRole(ROLE_TRAINER)}
                    className={classNames('focus:outline-none opacity-50', {
                      'opacity-100 font-bold': currentFilter === ROLE_TRAINER,
                    })}
                  >
                    Trainer (
                    {
                      filteredMemberDataForLength.filter((item: any) => {
                        return (
                          (getRoleByLabel(item[2].value) === ROLE_TRAINER || item[2].currentUser.isTrainerUser) ?? ''
                        );
                      }).length
                    }
                    )
                  </button>
                  <button
                    type="button"
                    onClick={() => filterMemberDataByRole(ROLE_COMPANY_ADMIN)}
                    className={classNames('focus:outline-none opacity-50', {
                      'opacity-100 font-bold': currentFilter === ROLE_COMPANY_ADMIN,
                    })}
                  >
                    Administrator (
                    {
                      filteredMemberDataForLength.filter(item => {
                        return (
                          (getRoleByLabel(item[2].value) === ROLE_COMPANY_ADMIN || item[2].currentUser.isAdminUser) ??
                          ''
                        );
                      }).length
                    }
                    )
                  </button>
                  <button
                    type="button"
                    onClick={() => filterMemberDataByRole(ROLE_MEMBER)}
                    className={classNames('focus:outline-none opacity-50', {
                      'opacity-100 font-bold': currentFilter === ROLE_MEMBER,
                    })}
                  >
                    Mitglied (
                    {
                      filteredMemberDataForLength.filter(item => {
                        return getRoleByLabel(item[2].value) === ROLE_MEMBER;
                      }).length
                    }
                    )
                  </button>
                  <button
                    type="button"
                    onClick={() => filterInvitedMemberData()}
                    className={classNames('focus:outline-none opacity-50', {
                      'opacity-100 font-bold': filterInvitedMembers === true,
                    })}
                  >
                    Eingeladen (
                    {
                      filteredMemberDataForLength.filter(item => {
                        return item[2].currentUser?.type === 'invite';
                      }).length
                    }
                    )
                  </button>
                  <button
                    type="button"
                    onClick={() => filteractiveMemberData()}
                    className={classNames('focus:outline-none opacity-50', {
                      'opacity-100 font-bold': filterActiveMembers === true,
                    })}
                  >
                    Aktiv (
                    {
                      filteredMemberDataForLength.filter(item => {
                        return (
                          (item[2].currentUser?.membership !== undefined || item[2].currentUser?.isAdminUser) &&
                          (item[2].currentUser?.accountDisabled === undefined ||
                            item[2].currentUser?.accountDisabled === false)
                        );
                      }).length
                    }
                    )
                  </button>
                  <button
                    type="button"
                    onClick={() => filterDisabledMembers()}
                    className={classNames('focus:outline-none opacity-50', {
                      'opacity-100 font-bold': disabledInvitedMembers === true,
                    })}
                  >
                    Gesperrt (
                    {
                      filteredMemberDataForLength.filter(item => {
                        return item[2].currentUser?.accountDisabled === true;
                      }).length
                    }
                    )
                  </button>
                </div>
                <div className="hidden tablet:flex gap-x-10 gap-y-10 large:gap-x-20 flex-wrap">
                  <div
                    className="rounded-full bg-accentColor p-5 large:p-10 cursor-pointer"
                    onClick={() => scrollRight()}
                    aria-hidden="true"
                  >
                    <div>
                      <ChevronLeftIcon width={25} height={25} className="text-buttonTextColor mx-auto" />
                    </div>
                  </div>
                  <div
                    className="rounded-full bg-accentColor p-5 large:p-10 cursor-pointer"
                    onClick={() => scrollLeft()}
                    aria-hidden="true"
                  >
                    <div>
                      <ChevronRightIcon width={25} height={25} className="text-buttonTextColor mx-auto" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Table
              header={header}
              body={filteredMemberData}
              sortFunction={sortTableData}
              customHeader={
                tenantData?.settings?.locations !== undefined && tenantData?.settings?.locations.length > 0
                  ? customHeaderWithLocations
                  : customHeader
              }
              className="w-full mb-40"
              stickyHeader
              notOverflow
            />
            <div className="flex justify-between flex-wrap gap-y-10">
              <div className="flex gap-10 my-auto">
                <div>
                  <CustomSelect
                    name="location"
                    dropDownOption={pagePerSiteOptions}
                    isFullSize
                    defaultValue={pagePerSiteOptions.filter((item: any) => item.value === 10)}
                    onChange={changeResultsPerPage}
                    className="z-60"
                  />
                </div>
                <div className="my-auto font-extralight">Mitglieder pro Seite</div>
              </div>
              <div className="flex gap-20">
                {currentPageIndex !== 0 ? (
                  <div className="my-auto">
                    <div className="hidden desktop:block">
                      <Button className="py-10 my-auto" onClick={() => pagePrevious()}>
                        Vorherige
                      </Button>
                    </div>
                    <div className="desktop:hidden">
                      <Button className="py-10 my-auto" onClick={() => pagePrevious()}>
                        {'<'}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="my-auto">
                    <div className="hidden desktop:block">
                      <Button className="py-10 cursor-default my-auto" buttonStyle="white">
                        Vorherige
                      </Button>
                    </div>
                    <div className="desktop:hidden">
                      <Button className="py-10 cursor-default my-auto" buttonStyle="white">
                        {'<'}
                      </Button>
                    </div>
                  </div>
                )}
                <div className="flex gap-5">
                  <div className="my-auto font-extralight">Seite:</div>
                  <CustomSelect
                    name="location"
                    dropDownOption={pagesOptions}
                    value={pagesOptions[currentPageForDropDown]}
                    isSearchable
                    isFullSize
                    onChange={changeResultPage}
                    className="z-60"
                  />
                </div>
                {filteredMemberData.length === currentResultPerPage ? (
                  <div className="my-auto">
                    <div className="hidden desktop:block">
                      <Button className="py-10 my-auto" onClick={() => pageNext()}>
                        Nächste
                      </Button>
                    </div>
                    <div className="desktop:hidden">
                      <Button className="py-10 my-auto" onClick={() => pageNext()}>
                        {'>'}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="my-auto">
                    <div className="hidden desktop:block">
                      <Button className="py-10 cursor-default my-auto" buttonStyle="white">
                        Nächste
                      </Button>
                    </div>
                    <div className="desktop:hidden">
                      <Button className="py-10 cursor-default my-auto" buttonStyle="white">
                        {'>'}
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
